<template>
    <el-dialog title="Angajati" :visible.sync="showPopup" class="my-dialog-class" width="60%">
        <el-form label-position="top" :inline="false" :rules="rules" label-width="100%" :model='selectedObject' ref='my-form' @submit.prevent="save" v-loading="loadingVisible" >

            <el-tag v-if="mode == 'add'" type="info" >Atentie! Parola creata automat va fi: <b style="color:red;"> "ananp00" </b></el-tag>

            <el-row :gutter="15" >
                
                <el-col :md='12'>
                    <el-form-item label='Nume' prop="Nume">
                        <el-input class='full-width' v-model='selectedObject.Nume' />
                    </el-form-item>
                </el-col>
                <el-col :md='12'>
                    <el-form-item label='E-mail' prop="Email">
                        <el-input class='full-width' v-model='selectedObject.Email' />
                    </el-form-item>
                </el-col>
                <el-col :md='12'>
                    <el-form-item label='Functie' prop="IdFunctie">
                        <el-select v-model='selectedObject.IdFunctie' class='full-width' filterable>        
                            <el-option v-for='item in Info.functii_angajati' :key="'functii_angajati' + item.Id" :label='item.Nume' :value='item.Id'></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :md='12'>
                    <el-form-item label='Tip angajat' prop="TipAngajat">
                        <el-select v-model="selectedObject.TipAngajat" placeholder="Alege" class='full-width' filterable>
                            <el-option v-for="item in OptiuniTipAngajat" :key="item.value" :label="item.label" :value="item.value"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :md='12'>
                    <el-form-item label='Norma de lucru' prop="NormaDeLucru">
                        <el-select v-model="selectedObject.NormaDeLucru" placeholder="Alege" class='full-width'>
                            <el-option v-for="(item,index) in normaDeLucru" :key="index" :value="item"></el-option>
                        </el-select>                        
                    </el-form-item>
                </el-col>
                <el-col :md='12'>
                    <el-form-item label='Departament' prop="IdGrupDeOrganizare">
                        <el-select v-model='selectedObject.IdGrupDeOrganizare' class='full-width' filterable>        
                            <el-option v-for='item in Info.departamente' :key="'departamente' + item.Id" :label='item.Nume' :value='item.Id'></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :md='12'>
                    <el-form-item label='Data incepere' prop="DataIncepere">
                        <el-date-picker
                            class='full-width'
                            v-model="selectedObject.DataIncepere"
                            type="date"
                            format="dd.MM.yyyy"
                            value-format='yyyy-MM-dd'
                            placeholder="Alege data"/>
                    </el-form-item>
                </el-col>
                 <el-col :md='12'>
                    <el-form-item label='Data incetare' prop="DataIncetare">
                        <el-date-picker
                            class='full-width'
                            v-model="selectedObject.DataIncetare"
                            type="date"
                            format="dd.MM.yyyy"
                            value-format='yyyy-MM-dd'
                            placeholder="Alege data"/>
                    </el-form-item>
                </el-col>
                <el-col :md='12'>
                    <el-form-item label='Grup de drepturi' prop="IdGrupDrepturi">
                        <el-select multiple v-model='selectedObject.IdGrupDrepturi' class='full-width' filterable>        
                            <el-option v-for='item in Info.rights_groups' :key="'rights-groups' + item.Id" :label='item.Name' :value='item.Id'></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :md='12'>
                     <el-checkbox v-model="selectedObject.EsteSters">Inactiv</el-checkbox>
                </el-col>

                <!-- TODO: campurile legate de salariu se scot momentan -->
                <!-- <el-col :md='8'>
                    <el-col style="margin-bottom:25px" :span="14">
                        <span class="tip">Salariu de baza</span>
                    </el-col>
                    <el-tooltip content="Salariu de baza conform deciziei presedintele ANANP">
                        <i class="el-icon-info"></i>
                    </el-tooltip>
                    <el-form-item prop="SalariuDeBazaDecizie">
                        <el-input-number class='full-width' v-model='selectedObject.SalariuDeBazaDecizie' :precision='2' :min='0'></el-input-number>
                    </el-form-item>
                </el-col>               
                <el-col :md='8'>
                    <el-col style="margin-bottom:25px" :span="16">
                        <span class="tip">Salariu ore proiecte</span>
                    </el-col>
                    <el-tooltip content="Salariu de baza pentru calculul orelor lucrate in proiect">
                        <i class="el-icon-info"></i>
                    </el-tooltip>
                    <el-form-item>
                        <el-input class='full-width' v-model='selectedObject.SalariuDeBazaCalculOre' disabled ></el-input>
                    </el-form-item>
                </el-col>
                <el-col :md='8'>
                    <el-col style="margin-bottom:25px" :span="12">
                        <span class="tip">Valoare spor</span>
                    </el-col>
                    <el-tooltip content="15%">
                        <i class="el-icon-info"></i>
                    </el-tooltip>
                    <el-form-item>
                        <el-input class='full-width' v-model='selectedObject.ValoareSpor' disabled ></el-input>
                    </el-form-item>
                </el-col> -->               
            </el-row>
            <el-row :gutter="15">
                <span style="font-size: 12px; color: red;margin-left: 15px" v-if="Eroare"> {{ MesajEroare }}</span>
            </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer" >    
            <el-button @click="showPopup=false" > Renunta  </el-button>            
            <el-button v-if="mode == 'add'" type="success" @click="save(true)"> Salveaza si creeaza utilizator </el-button>
            <el-button v-else type="primary" @click="save" > Salveaza </el-button>
        </span>
    </el-dialog>
</template>

<script>
    import settings from "@/backend/LocalSettings";
    import BasePage from '@/pages/BasePage';

    export default {
        name: "Angajati_dialog",
        extends: BasePage,        
        data () {
            return {
                baseUrl :'',
                showPopup: false,
                mode: 'add',
                Eroare: false,
                MesajEroare: '',
                selectedObject: { Nume: '', Email: '', IdGrupDrepturi: [], IdFunctie: '', NormaDeLucru: Number, SalariuDeBazaDecizie:'', SalariuDeBazaCalculOre:0, ValoareSpor:'0', TipAngajat:'', IdGrupDeOrganizare:'', Proiecte:[], IdUtilizator:'-1' },
                Info:{ functii_angajati: [], proiecte:[], rights_groups: [] },                    
                rules: {
                    Nume:                   [ { required: true, message: 'Campul este obligatoriu' } ], 
                    IdFunctie:              [ { required: true, message: 'Campul este obligatoriu' } ], 
                    NormaDeLucru:           [ { required: true, message: 'Campul este obligatoriu' } ], 
                    SalariuDeBazaDecizie:   [ { required: true, message: 'Campul este obligatoriu' } ], 
                    TipAngajat:             [ { required: true, message: 'Campul este obligatoriu' } ], 
                    IdGrupDeOrganizare:     [ { required: true, message: 'Campul este obligatoriu' } ], 
                    Email:                  [ { required: true, message: 'Campul este obligatoriu' } ],
                    IdGrupDrepturi:         [ { required: true, message: 'Campul este obligatoriu' } ]
                },
                OptiuniTipAngajat: [{ value: 'Propriu', label: 'Propriu'}, { value: 'Cooptat',label: 'Cooptat' }],
                normaDeLucru: [4,8],
                grupuri_drepturi:[]
            }
        },
         watch: {
           'selectedObject.SalariuDeBazaDecizie': function (newVal, oldVal) {
                if(parseInt(newVal) > 0 || newVal != oldVal){
                    this.selectedObject.SalariuDeBazaCalculOre = parseFloat(newVal / (100 + settings.ProcentSporSalarii) * 100).toFixed(2);
                    this.selectedObject.ValoareSpor            = parseFloat(this.selectedObject.SalariuDeBazaCalculOre * settings.ProcentSporSalarii / 100).toFixed(2);
                }
            }
        },
        methods: {
            show_me: async function( id ) {
                this.selectedObject = { Nume: '', Email: '', IdGrupDrepturi: [], IdFunctie: '', SalariuDeBazaDecizie:'', SalariuDeBazaCalculOre:0, ValoareSpor:0, TipAngajat:'', IdGrupDeOrganizare:'', Proiecte:[], IdUtilizator:'-1' }
                this.showPopup      = true;
                this.selectedObject.NormaDeLucru = Math.max( ...this.normaDeLucru )
                if( id == null )
                {
                    this.mode = "add";
                }
                else
                {
                    this.mode           = "edit";
                    var result          = await this.post("angajati/get_info_item_dialog", { id: id } );
                    this.selectedObject = result.Item;
                    this.selectedObject.EsteSters = Boolean(parseInt(this.selectedObject.EsteSters));
                }
            },
            async get_info(){
                var response                = await this.post("angajati/get_info_for_dialog" );
                this.Info.functii_angajati  = response.functii_angajati;                                 
                this.Info.departamente      = response.departamente;
                this.Info.rights_groups     = response.rights_groups
            },
            save: async function(creeaza_utilizator = false) {              
                this.$refs['my-form'].validate( async(valid) => {
                    if (valid)
                    {
                        if(this.selectedObject.EsteSters)
                            this.selectedObject.EsteSters = 1;
                        else 
                            this.selectedObject.EsteSters = 0;
                            
                        let res = await this.post("angajati/save", { mode: this.mode, object: this.selectedObject, create_user: creeaza_utilizator, grupuri_drepturi: this.grupuri_drepturi } );
                        
                        if(res.MesajEroare != ''){
                            this.Eroare = true;
                            this.MesajEroare = res.MesajEroare;
                            return;
                        }
                        
                        this.showPopup = false;
                        this.$emit("save");
                    }
                });
            }
        },
        mounted: function() {
            this.baseUrl = settings.BASE_URL;
            this.get_info();
        }
    };
</script>

<style lang="less" scoped>
    .full-width {
        width: 100%;
    }
    .tip:before {        
        content: '*';
        color: #F56C6C;
        margin-right: 4px;
    }

</style>